import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  en: {
    translation: {
      'AI-CONSULTANT': 'AI-CONSULTANT',
      'Logout': 'Logout',
      'Profile': 'Profile',
      'General': 'General',
      'Security': 'Security',
      'Login': 'Login',
      'Confirm': 'Confirm',
      'Delete': 'Delete',
      'Are you sure you want to delete this item': 'Are you sure you want to delete this item',
      'Are you sure?': 'Are you sure?',
      'Log in': 'Log in',
      'Log in on the internal platform': 'Log in on the internal platform',
      'Forgot password': 'Forgot password',
      'Create new account': 'Create new account',
      'Email Address': 'Email Address',
      'Password': 'Password',
      'Overview': 'Overview',
      'Proposition': 'Proposition',
      'Your Propositions': 'Your Propositions',
      'Create Proposition': 'Create Proposition',
      'Consultant': 'Consultant',
      'Specialist': 'Specialist',
      'List specialist': 'List specialist',
      'Create specialist': 'Create specialist',
      'Dashboard': 'Dashboard',
      'Approve': 'Approve',
      'Reject': 'Reject',
      'Email': 'Email',
      'Name': 'Name',
      'Propositions': 'Propositions',
      'Proposition updated!': 'Proposition updated!',
      'Cancel': 'Cancel',
      'Open Propositions': 'Open Propositions',
      'Proposition created!': 'Proposition created!',
      'Something went wrong!': 'Something went wrong!',
      'Create Specialist': 'Create Specialist',
      'Create new proposition': 'Create new proposition',
      'Create new specialist': 'Create new specialist',
      'Consultant Status': 'Consultant Status',
      'Title': 'Title',
      'Description': 'Description',
      'Category': 'Category',
      'Expected Outcome': 'Expected Outcome',
      'Create': 'Create',
      'Detail': 'Detail',
      'Publications': 'Publications',
      'Works': 'Works',
      'Work': 'Work',
      'Skills': 'Skills',
      'Status': 'Status',
      'Consult': 'Consult',
      'File': 'File',
      'Actions': 'Actions',
      'Download': 'Download',
      'Publication': 'Publication',
      'Publication URL': 'Publication URL',
      'Publication Date': 'Publication Date',
      'Save Changes': 'Save Changes',
      'Choose File': 'Choose File',
      'Phone': 'Phone',
      'Register': 'Register',
      'Terms and Conditions': 'Terms and Conditions',
      'I have read the': 'I have read the',
      'Must be a valid email': 'Must be a valid email',
      'Email is required': 'Email is required',
      'Phone is required': 'Phone is required',
      'This field must be checked': 'This field must be checked',
      'Firstname is required': 'Firstname is required',
      'Lastname is required': 'Lastname is required',
      'Proposition deleted!': 'Proposition deleted!',
      'Profile updated!': 'Profile updated!',
      'Profile picture updated!': 'Profile picture updated!',
      'Specialist request!': 'Specialist request!',
      'Your role': 'Your role',
      'Upload Picture': 'Upload Picture',
      'Request Consultant': 'Request Consultant',
      'Consultant status is': 'Consultant status is',
      'Firstname': 'Firstname',
      'Lastname': 'Lastname',
      'Linkedin': 'Linkedin',
      'Github': 'Github',
      'Skill': 'Skill',
      'Role': 'Role',
      'Level': 'Level',
      'Password is required': 'Password is required',
      'Company name': 'Company name',
      'Verify email': 'Verify email',
      'Verify email success!': 'Verify email success!',
      'Token invalid!': 'Token invalid!',
      'Phone Number': 'Phone Number',
      'We will use this email to contact you': 'We will use this email to contact you'
    }
  },
  th: {
    translation: {
      'AI-CONSULTANT': 'AI-CONSULTANT',
      'Logout': 'Logout',
      'Profile': 'โปรไฟล์',
      'Login': 'Login',
      'Confirm': 'Confirm',
      'Delete': 'Delete',
      'Are you sure you want to delete this item': 'Are you sure you want to delete this item',
      'Are you sure?': 'Are you sure?',
      'Log in': 'Log in',
      'Log in on the internal platform': 'Log in on the internal platform',
      'Forgot password': 'Forgot password',
      'Create new account': 'Create new account',
      'Email Address': 'Email Address',
      'Password': 'Password',
      'Overview': 'Overview',
      'Proposition': 'Proposition',
      'Your Propositions': 'Your Propositions',
      'Create Proposition': 'Create Proposition',
      'Consultant': 'Consultant',
      'Specialist': 'Specialist',
      'List specialist': 'List specialist',
      'Create specialist': 'Create specialist',
      'Dashboard': 'Dashboard',
      'Approve': 'Approve',
      'Reject': 'Reject',
      'Email': 'Email',
      'Name': 'Name',
      'Propositions': 'Propositions',
      'Proposition updated!': 'Proposition updated!',
      'Cancel': 'Cancel',
      'Open Propositions': 'Open Propositions',
      'Proposition created!': 'Proposition created!',
      'Something went wrong!': 'Something went wrong!',
      'Create Specialist': 'Create Specialist',
      'Create new proposition': 'Create new proposition',
      'Create new specialist': 'Create new specialist',
      'Consultant Status': 'Consultant Status',
      'Title': 'Title',
      'Description': 'Description',
      'Category': 'Category',
      'Expected Outcome': 'Expected Outcome',
      'Create': 'Create',
      'Detail': 'Detail',
      'Status': 'Status',
      'Consult': 'Consult',
      'File': 'File',
      'Actions': 'Actions',
      'Download': 'Download',
      'Publication': 'Publication',
      'Publication URL': 'Publication URL',
      'Publication Date': 'Publication Date',
      'Save Changes': 'Save Changes',
      'Choose File': 'Choose File',
      'Phone': 'Phone',
      'Register': 'Register',
      'Terms and Conditions': 'Terms and Conditions',
      'I have read the': 'I have read the',
      'Must be a valid email': 'Must be a valid email',
      'Email is required': 'Email is required',
      'Phone is required': 'Phone is required',
      'This field must be checked': 'This field must be checked',
      'Firstname is required': 'Firstname is required',
      'Lastname is required': 'Lastname is required',
      'Proposition deleted!': 'Proposition deleted!',
      'Profile updated!': 'Profile updated!',
      'Profile picture updated!': 'Profile picture updated!',
      'Specialist request!': 'Specialist request!',
      'Your role': 'Your role',
      'Upload Picture': 'Upload Picture',
      'Request Consultant': 'Request Consultant',
      'Consultant status is': 'Consultant status is',
      'Firstname': 'Firstname',
      'Lastname': 'Lastname',
      'Linkedin': 'Linkedin',
      'Github': 'Github',
      'Skill': 'Skill',
      'Level': 'Level',
      'Password is required': 'Password is required',
      'Company name': 'Company name',
      'Phone Number': 'Phone Number',
      'We will use this email to contact you': 'We will use this email to contact you'
    }
  }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  })
