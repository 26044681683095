import { lazy, Suspense } from 'react'
import AuthGuard from './components/AuthGuard'
import DashboardLayout from './components/dashboard/DashboardLayout'
import GuestGuard from './components/GuestGuard'
import LoadingScreen from './components/LoadingScreen'
import MainLayout from './components/MainLayout'

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen/>}>
    <Component {...props} />
  </Suspense>
)

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')))
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')))
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')))
const Register = Loadable(lazy(() => import('./pages/authentication/Register')))
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')))

// Dashboard pages

const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')))
const Account = Loadable(lazy(() => import('./pages/dashboard/Account')))
const PropositionList = Loadable(lazy(() => import('./pages/dashboard/PropositionList')))
const PropositionOpenList = Loadable(lazy(() => import('./pages/dashboard/PropositionOpenList')))
const PropositionDetail = Loadable(lazy(() => import('./pages/dashboard/PropositionDetail')))
const PropositionOpenDetail = Loadable(lazy(() => import('./pages/dashboard/PropositionOpenDetail')))
const PropositionConsultDetail = Loadable(lazy(() => import('./pages/dashboard/PropositionConsultDetail')))
const SpecialList = Loadable(lazy(() => import('./pages/dashboard/SpecialList')))
const SpecialListDetail = Loadable(lazy(() => import('./pages/dashboard/SpecialListDetail')))
const PropositionCreatePage = Loadable(lazy(() => import('./pages/dashboard/PropositionCreatePage')))
const SpecialListCreatePage = Loadable(lazy(() => import('./pages/dashboard/SpecialistCreatePage')))
const PropositionConsult = Loadable(lazy(() => import('./pages/dashboard/PropositionConsult')))

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')))
const NotFound = Loadable(lazy(() => import('./pages/NotFound')))
const ServerError = Loadable(lazy(() => import('./pages/ServerError')))

// Other pages

const Home = Loadable(lazy(() => import('./pages/Home')))

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login/>
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login/>
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery/>
      },
      {
        path: 'password-reset',
        element: <PasswordReset/>
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register/>
          </GuestGuard>
        )
      },
      {
        path: 'register-unguarded',
        element: <Register/>
      },
      {
        path: 'verify-code',
        element: <VerifyCode/>
      }
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout/>
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Overview/>
      },
      {
        path: 'account',
        element: <Account/>
      },
      {
        path: 'propositions',
        element: <PropositionList/>
      },
      {
        path: 'propositions/open',
        element: <PropositionOpenList/>
      },
      {
        path: 'propositions/:id',
        element: <PropositionDetail/>
      },
      {
        path: 'propositions/open/:id',
        element: <PropositionOpenDetail/>
      },
      {
        path: 'propositions/create',
        element: <PropositionCreatePage/>
      },
      {
        path: 'propositions/consultant',
        element: <PropositionConsult/>
      },
      {
        path: 'propositions/consultant/:id',
        element: <PropositionConsultDetail/>
      },
      {
        path: 'specialist',
        element: <SpecialList/>
      },
      {
        path: 'specialist/:id',
        element: <SpecialListDetail/>
      },
      {
        path: 'specialist/create',
        element: <SpecialListCreatePage/>
      }
    ]
  },
  {
    path: '',
    element: <MainLayout/>,
    children: [
      {
        path: '',
        element: <Home/>
      },
      {
        path: '401',
        element: <AuthorizationRequired/>
      },
      {
        path: '404',
        element: <NotFound/>
      },
      {
        path: '500',
        element: <ServerError/>
      },
      {
        path: '*',
        element: <NotFound/>
      }
    ]
  }
]

export default routes
