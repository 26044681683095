import { createContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { authApi } from '../api/authApi'

const initialState: any = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
}

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    }
  },
  LOGIN: (state, action) => {
    const { user } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user
    }
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state) => ({ ...state })
}

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state)

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  setUser: () => Promise.resolve()
})

export const AuthProvider = (props) => {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken')

        if (accessToken) {
          const user = await authApi.me(accessToken)

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          })
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          })
        }
      } catch (err) {
        console.error(err)
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        })
      }
    }

    initialize()
  }, [])

  const login = async (email, password) => {
    const accessToken = await authApi.login({ email, password })
    const user = await authApi.me(accessToken)
    localStorage.setItem('accessToken', accessToken)

    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    })
  }

  const setUser = async (user) => {
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    })
  }

  const loadMe = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken')
      const user = await authApi.me(accessToken)
      setUser(user)
    } catch (e) {
    }
  }

  const logout = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken')
      await authApi.logout(accessToken!)
    } finally {
      localStorage.removeItem('accessToken')
      dispatch({ type: 'LOGOUT' })
    }
  }

  const register = async (data) => {
    await authApi.register(data)
    dispatch({
      type: 'REGISTER'
    })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        setUser,
        register,
        loadMe
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default AuthContext
