import PropTypes from 'prop-types'
import { matchPath } from 'react-router-dom'
import { List, ListSubheader } from '@material-ui/core'
import NavItem from './NavItem'
import { useTranslation } from 'react-i18next'
import useAuth from '../hooks/useAuth'

const renderNavItems = ({ depth = 0, items, pathname, user }, t) => (
  <List disablePadding>
    {items.reduce(
      // eslint-disable-next-line no-use-before-define
      (acc, item) => reduceChildRoutes({
        acc,
        item,
        user,
        pathname,
        depth
      }, t), []
    )}
  </List>
)

const reduceChildRoutes = ({ acc, pathname, item, depth, user }, t) => {
  const key = `${item.title}-${depth}`
  const exactMatch = item.path ? !!matchPath({
    path: item.path,
    end: true
  }, pathname) : false

  if (item.children) {
    const partialMatch = item.path ? !!matchPath({
      path: item.path,
      end: false
    }, pathname) : false

    acc.push(
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={t(item.title)}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname
        })}
      </NavItem>
    )
  } else {
    if (item.roles.includes(user.role)) {
      acc.push(
        <NavItem
          active={exactMatch}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          path={item.path}
          title={t(item.title)}
        />
      )
    }
  }

  return acc
}

const NavSection = (props) => {
  const { items, pathname, title, ...other } = props
  const { t } = useTranslation()
  const { user } = useAuth()
  return (
    <List
      subheader={(
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: 'text.primary',
            fontSize: '0.75rem',
            lineHeight: 2.5,
            fontWeight: 700,
            textTransform: 'uppercase'
          }}
        >
          {t(title)}
        </ListSubheader>
      )}
      {...other}
    >
      {renderNavItems({
        items,
        pathname,
        user
      }, t)}
    </List>
  )
}

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string
}

export default NavSection
