import { useEffect } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Avatar, Box, Divider, Drawer, Typography } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useAuth from '../../hooks/useAuth'
import ChartSquareBarIcon from '../../icons/ChartSquareBar'
import Logo from '../Logo'
import NavSection from '../NavSection'
import Scrollbar from '../Scrollbar'
import { API } from '../../config'
import { useTranslation } from 'react-i18next'

const DashboardSidebar = (props) => {
  const { t } = useTranslation()
  const sections = [
    {
      title: t('Dashboard'),
      roles: ['user', 'specialist', 'admin'],
      items: [
        {
          title: t('Overview'),
          path: '/dashboard',
          icon: <ChartSquareBarIcon fontSize="small"/>,
          roles: ['user', 'specialist', 'admin']
        }
      ]
    },
    {
      title: t('Proposition'),
      roles: ['user', 'specialist', 'admin'],
      items: [
        {
          title: t('Your Propositions'),
          path: '/dashboard/propositions',
          roles: ['user', 'specialist', 'admin']
        },
        {
          title: t('Open Propositions'),
          path: '/dashboard/propositions/open',
          roles: ['specialist', 'admin']
        },
        {
          title: t('Create Proposition'),
          path: '/dashboard/propositions/create',
          roles: ['user', 'specialist', 'admin']
        },
        {
          title: t('Consultant'),
          path: '/dashboard/propositions/consultant',
          roles: ['specialist', 'admin']
        }
      ]
    },
    {
      title: t('Specialist'),
      roles: ['admin'],
      items: [
        {
          title: t('List specialist'),
          roles: ['admin'],
          path: '/dashboard/specialist'
        },
        {
          title: t('Create specialist'),
          roles: ['admin'],
          path: '/dashboard/specialist/create'
        }
      ]
    }
  ]

  const { onMobileClose, openMobile } = props
  const location = useLocation()
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const { user } = useAuth()

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [location.pathname])

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={user['profileImage'] ? `${API.base}/profile-images/${user['profileImage']}` : ''}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {user.firstname} {user.lastname}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {t('Your role')}: {user.role}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider/>
        <Box sx={{ p: 2 }}>
          {sections.map((section) => {
              return section.roles.includes(user.role) && <NavSection
                key={section.title}
                pathname={location.pathname}
                sx={{
                  '& + &': {
                    mt: 3
                  }
                }}
                {...section}
              />
            }
          )}
        </Box>
      </Scrollbar>
    </Box>
  )

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    )
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  )
}

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
}

export default DashboardSidebar
