import { requester } from './requester'
import { get } from 'lodash'

class AuthApi {
  async login ({ email, password }): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await requester.post('v1/auth/sign-in', { email, password })
        resolve(user.data.data.attributes.accessToken)
      } catch (err) {
        if (get(err, 'response.data.errors[0].title', null)) {
          reject(new Error(get(err, 'response.data.errors[0].title', null)))
        }
        reject(new Error('Internal server error'))
      }
    })
  }

  async register (data) {
    return new Promise(async (resolve, reject) => {
      try {
        await requester.post('v1/auth/sign-up', {
          'email': data.email,
          'password': data.password,
          'firstname': data.firstname,
          'lastname': data.lastname,
          'phone': data.phone
        })
        resolve(true)
      } catch (err) {
        if (get(err, 'response.data.errors[0].title', null)) {
          reject(new Error(get(err, 'response.data.errors[0].title', null)))
        }
        reject(new Error('Internal server error'))
      }
    })
  }

  async updateProfile (data: any) {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await requester.put('v1/users/profile', data, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`
          }
        })
        resolve(user.data.data.attributes)
      } catch (err) {
        console.error('[Auth Api]: ', err)
        if (get(err, 'response.data.errors[0].meta[0]', null)) {
          reject(new Error(get(err, 'response.data.errors[0].meta[0]', null)))
        }
        reject(new Error('Internal server error'))
      }
    })
  }

  async uploadImage (file: any) {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const user = await requester.post('v1/users/profile/image-upload', formData, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      return user.data.data.attributes
    } catch (err) {
      console.error('[Auth Api]: ', err)
      throw new Error('Internal server error')
    }
  }

  me (accessToken) {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await requester.get('v1/users/profile', {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })

        if (!user) {
          reject(new Error('Invalid authorization token'))
          return
        }

        resolve(user.data.data.attributes)
      } catch (err) {
        console.error('[Auth Api]: ', err)
        reject(new Error('Internal server error'))
      }
    })
  }

  verify (token: string) {
    return new Promise(async (resolve, reject) => {
      try {
        await requester.get(`v1/auth/verify/${token}`)
        resolve(true)
      } catch (err) {
        console.error('[Auth Api]: ', err)
        reject(new Error('Internal server error'))
      }
    })
  }

  async logout (accessToken: string) {
    return new Promise(async (resolve, reject) => {
      try {
        await requester.delete(`v1/auth/logout/${accessToken}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        resolve(true)
      } catch (err) {
        console.error('[Auth Api]: ', err)
        reject(new Error('Internal server error'))
      }
    })
  }
}

export const authApi = new AuthApi()
