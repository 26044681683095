import { useRef, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from '@material-ui/core'
import useAuth from '../../hooks/useAuth'
import UserIcon from '../../icons/User'
import { API } from '../../config'
import { useTranslation } from 'react-i18next'

const AccountPopover = () => {
  const anchorRef = useRef(null)
  const { user, logout } = useAuth()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = async () => {
    try {
      handleClose()
      await logout()
      navigate('/')
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <Avatar
          src={user['profileImage'] ? `${API.base}/profile-images/${user['profileImage']}` : ''}
          sx={{
            height: 32,
            width: 32
          }}
        />
        <Typography
          color="textWhite"
          variant="h6"
          sx={{ pl: 2 }}
        >
          {user.firstname} {user.lastname}
        </Typography>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {user.firstname} {user.lastname}
          </Typography>
          <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            {user.email}
          </Typography>
        </Box>
        <Divider/>
        <Box sx={{ mt: 2 }}>
          <MenuItem
            component={RouterLink}
            to="/dashboard/account"
          >
            <ListItemIcon>
              <UserIcon fontSize="small"/>
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {t('Profile')}
                </Typography>
              )}
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            {t('Logout')}
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default AccountPopover
